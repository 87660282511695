export default {
  methods:{
    showName(){
      alert('子组件的点击');
    }
  },
  render(h) {
    return (
      <div style={{ marginLeft: '100px', paddingTop: '50px' }}> 这是子组件11111
      
        <button onClick={this.showName}>button</button>
       </div>
    )
  }
}