
<script>
import child1 from './child1.jsx'
import child2 from './child2.jsx'
export default {
  // functional: true, // 当组件仅需要展示，而不需要进行其他操作时，不用维护状态，可使用 functional进行标识，性能会好很多. 函数式组件是没有this的。所有的内容都在render的第二个参数里
  data() {
    return {
      formInline: {
        user: '',
        region: ''
      },
      text: '',
      address: 'https://www.baidu.com',
      show: true
    }
  },
  components: {
    child1,
    child2
  },
  methods: {
    $_handleInputUser(e) {
      console.log(e.target.value);
      this.formInline.user = e.target.value
    },
    $_handleChangeRegion(value) {
      this.formInline.region = value
    },
    $_handleSubmit() { }
  },
  created() {
    console.log('created');
  },
  beforeMount() {
    console.log('beforeMount');
  },
  mounted() {
    console.log('mounted');
  },
  updated() {
    console.log('updated');
  },
  /**
  *将 h 作为 createElement 的别名是 Vue 生态系统中的一个通用惯例，实际上也是 JSX 所要求的。从 Vue 的 Babel 插件的 3.4.0     *版本开始，我们会在以 ES2015 语法声明的含有 JSX 的任何方法和 getter 中 (不是函数或箭头函数中) 自动注入 
  *const h = this.$createElement，这样你就可以去掉 (h) 参数了。对于更早版本的插件，如果 h 在当前作用域中不可用，应用会抛错。
    */
  render(h) {
    console.log('render');
    // {} 插值表达式
    // v-model不可用 由 value属性 + input事件 代替
    // onClick点击事件
    // 绑定变量 content={this.generatedText} 
    // 将HTML字符串设置为元素的内容，使用domPropsInnerHTML而不是使用v-html ex:domPropsInnerHTML={htmlContent}
    // jsx 无法使用v-for v-if
    // 遍历使用map
    // 条件判断使用 && 或 三元表达式

    let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
      <div style={{ marginLeft: '100px', paddingTop: '50px' }}>
        <form inline model={this.formInline} class="demo-form-inline">
          <input
            value={this.formInline.user}
            onInput={this.$_handleInputUser}
            placeholder="审批人"
            class={'form-control'}
          ></input>
          <button type="primarty" onClick={this.$_handleSubmit}>
            查询
          </button>
        </form >
        {arr.map(item => <a style={{ color: 'blue' }} href={this.address} class={'text','link'}>{item}</a>)}
        {this.show && <ul>
          <li>如果show条件为真时 ,则展示这条li</li>
        </ul>}
        {this.show ? <div>如果show条件为真时 ,则展示这条div</div> : <div>如果show条件为假时 ,则不展示这条div</div>}
        {<child1 />}
        {<child2 />} 
      </div>


    )



  }
}
</script>

<style scoped>
.form-control {
  margin-top: 20px;
}
.text {
  display: block;
  color: red;
}
.link {
  display: block;
  color: red;
}
</style>